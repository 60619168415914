/* eslint-disable */
import { gql } from '@apollo/client';
import clientApollo from './clientApollo';

const connectors = {
  getUnifiedCatalog: variables =>
    clientApollo.query({
      query: gql`
        query unifiedCatalog($realm: String!, $category: String) {
          unifiedCatalog(realm: $realm, category: $category) {
            connectors {
              title
              deprecated
              documentation
              image
              shape
              category
              type
              name
              jsonExample
              resourceId
              keyName
              experimental
              iconURL
              documentationURL
            }
            triggers {
              title
              deprecated
              documentation
              image
              shape
              category
              type
              name
              keyName
              iconURL
              documentationURL
            }
            capsules {
              version
              image
              groupId
              groupName
              collectionId
              collectionName
              colorDefault
              colorBackground
              colorAccent
              id
              resourceId
              name
              description
              active
              realmName
              category
              shape
              capsule {
                title
                colorAccent
                colorBackground
              }
              keyName
              iconName
              versionMajor
              versionMinor
            }
          }
        }
      `,
      variables
    }),
  get: async variables => {
    try {
      const componentsPromise = clientApollo.query({
        query: gql`
          query componentsQuery($realm: String!, $betaShapes: Boolean) {
            components(realm: $realm, betaShapes: $betaShapes) {
              name
              category
              jsonExample
              jsonSchema
              jsonSchemaEdge
              visual
              type
              iconName
              camelConnector
              experimental
              iconURL
              shape
              deprecated
              title
              documentationURL
            }
          }
        `,
        variables
      });
      const capsulesConsumersPromise = clientApollo.query({
        query: gql`
          query capsulesConsumersQuery($realm: String!) {
            capsulesConsumers(realm: $realm) {
              id
              name
              description
              icon
              colorDefault
              colorBackground
              colorAccent
              capsuleCollectionHeader
              iconName
              iconURL
              realmMaintainer {
                name
              }
              capsuleServices {
                id
                name
                capsuleCollectionGroup
                description
                icon
                iconName
                iconURL
                inSpec
                title
                versionMajor
                versionMinor
                versionFix
                type
                configExample
                disabled
                jsonSchema
                visual
                shape
                documentation
              }
            }
          }
        `,
        variables
      });
      const triggersPromise = clientApollo.query({
        query: gql`
          query triggersQuery($realm: String!) {
            triggers(realm: $realm) {
              name
              jsonExample
              configurable
              icon
              iconName
              iconURL
              jsonSchema
              documentationURL
              category
            }
          }
        `,
        variables
      });
      const replicasPromise = clientApollo.query({
        query: gql`
          query replicasQuery($realm: String!) {
            replicas(realm: $realm) {
              label
            }
          }
        `,
        variables
      });

      const [
        componentsResult,
        capsulesConsumersResult,
        triggersResult,
        replicasResult
      ] = await Promise.all([
        componentsPromise,
        capsulesConsumersPromise,
        triggersPromise,
        replicasPromise
      ]);

      return {
        data: {
          components: componentsResult.data.components,
          capsulesConsumers: capsulesConsumersResult.data.capsulesConsumers,
          triggers: triggersResult.data.triggers,
          replicas: replicasResult.data.replicas
        }
      };
    } catch (e) {
      return {
        data: {
          components: [],
          capsulesConsumers: [],
          triggers: [],
          replicas: []
        }
      };
    }
  },
  getReplicas: variables =>
    clientApollo.query({
      query: gql`
        query data($realm: String!) {
          replicas(realm: $realm) {
            label
          }
        }
      `,
      variables
    }),
  searchUnifiedCatalog: variables =>
    clientApollo.query({
      query: gql`
        query searchUnifiedCatalog($realm: String!, $search: JSON) {
          searchUnifiedCatalog(realm: $realm, search: $search) {
            content {
              description
              name
              category
              customMetadata
              id
              endpoint
              method
              endpointURL
              environment
              versionMajor
              versionMinor
              title
              documentationURL
              iconURL
              type
              colorDefault
              colorBackground
              colorAccent
              iconName
              resourceId
              keyName
            }
            first
            last
            number
            totalElements
            totalPages
            size
          }
        }
      `,
      variables
    })
};

export default connectors;
