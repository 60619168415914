import Immutable from 'seamless-immutable';
import { dataStreaming } from '~/api';
import i18n from '~/common/helpers/i18n';

const initDataStreamingState = {
  id: undefined,
  exporter: '',
  config: {
    host: '',
    apiKey: ''
  },
  contexts: [],
  health: {
    status: '',
    lastUpdate: ''
  }
};

const initialState = Immutable({
  result: {
    dataStreamingConfig: []
  },
  entities: {
    dataStreamingConfig: {}
  },
  loading: false,
  error: null,
  modal: {
    visible: false,
    loading: false,
    success: null,
    error: null,
    dataStreaming: initDataStreamingState
  },
  modalDataStreamingConfig: {
    visible: false,
    loading: false
  }
});

const dataStreamingModel = {
  host: 'dataStreaming',
  state: initialState,
  reducers: {
    updateDataStreamingStatus: (state, { id, active }) => {
      if (state.entities.dataStreamingConfig[id]) {
        return state.merge({
          entities: {
            dataStreamingConfig: {
              ...state.entities.dataStreamingConfig,
              [id]: {
                ...state.entities.dataStreamingConfig[id],
                active
              }
            }
          }
        });
      }
      return state;
    },
    setDataStreamings: (state, payload) => {
      const dataStreamings = Array.isArray(payload) ? payload : [];
      const dataStreamingIds = dataStreamings.map(
        dataStreaming => dataStreaming.id
      );
      const dataStreamingEntities = dataStreamings.reduce(
        (acc, dataStreaming) => {
          acc[dataStreaming.id] = dataStreaming;
          return acc;
        },
        {}
      );

      return state.merge({
        result: {
          dataStreamingConfig: dataStreamingIds
        },
        entities: {
          dataStreamingConfig: dataStreamingEntities
        }
      });
    },
    setDataStreamingInitialValue: (state, payload) =>
      state.merge({
        dataStreamingInitialValue: payload
      }),
    setModal: (state, payload) => {
      const newState = state.merge({
        modal: {
          visible: payload.visible,
          loading: payload.loading || false,
          success: payload.success || null,
          error: payload.error || null,
          dataStreaming: payload.dataStreaming || initDataStreamingState
        }
      });
      return newState;
    },
    setModalLoading: (state, loading) =>
      state.merge({
        modal: {
          ...state.modal,
          loading
        }
      }),
    setActionModal: (state, payload) =>
      state.merge({
        modal: {
          ...state.modal,
          ...payload
        }
      }),
    setLoading: (state, payload) =>
      state.merge({
        loading: payload
      }),
    clearDataStreaming: state =>
      state.merge({ dataStreamingInitialValue: initDataStreamingState }),
    setSuccess: (state, payload) => state.merge({ success: payload }),
    reset: () => initialState
  },
  effects: dispatch => ({
    async createDataStreaming(props) {
      try {
        const request = await dataStreaming.createDataStreamingConfig({
          realm: props.realm,
          dataStreaming: props.dataStreaming
        });

        dispatch.dataStreaming.setModalLoading(request, {
          snackbar: {
            text: i18n.t('label.dataStreaming_set_create_msg_success'),
            action: {
              label: i18n.t('action.close')
            }
          }
        });

        dispatch.dataStreaming.getDataStreamings({
          realm: props.realm,
          mock: false
        });

        dispatch.dataStreaming.setSuccess(true);
      } catch (e) {
        dispatch.dataStreaming.setModalLoading(e, {
          snackbar: {
            text: i18n.t(
              'label.dataStreaming_not_set_create_msg_dataStreaming'
            ),
            action: {
              label: i18n.t('action.close')
            }
          }
        });
      } finally {
        setTimeout(() => {
          dispatch.dataStreaming.setSuccess(false);
        }, 1000);
      }
    },
    async updateDataStreaming(props) {
      try {
        const request = await dataStreaming.updateDataStreamingConfig({
          realm: props.realm,
          dataStreaming: props.dataStreaming
        });

        dispatch.dataStreaming.setModalLoading(request, {
          snackbar: {
            text: i18n.t('label.dataStreaming_set_up_msg_success'),
            action: {
              label: i18n.t('action.close')
            }
          }
        });

        dispatch.dataStreaming.getDataStreamings({
          realm: props.realm,
          mock: false
        });

        dispatch.dataStreaming.setSuccess(true);
      } catch (e) {
        dispatch.dataStreaming.setModalLoading(e, {
          snackbar: {
            text: i18n.t('label.dataStreaming_not_set_up_msg_dataStreaming'),
            action: {
              label: i18n.t('action.close')
            }
          }
        });
      } finally {
        setTimeout(() => {
          dispatch.dataStreaming.setSuccess(false);
        }, 1000);
      }
    },
    async getDataStreamings({ realm, mock }) {
      try {
        dispatch.dataStreaming.setLoading(true);
        let data = [];
        if (mock) {
          data = await dataStreaming.getDataStreamingConfigMOCK({ realm });
        } else {
          data = await dataStreaming.getDataStreamingConfig({ realm });
        }

        dispatch.dataStreaming.setDataStreamings(data);
      } catch (e) {
        dispatch.dataStreaming.setModalLoading(e, {
          snackbar: {
            text: i18n.t('label.dataStreaming_failed_msg'),
            action: {
              label: i18n.t('action.close')
            }
          }
        });
      } finally {
        setTimeout(() => {
          dispatch.dataStreaming.setLoading(false);
        }, 1000);
      }
    },
    async removeDataStreaming({ id, realm }) {
      try {
        const data = await dataStreaming.removeDataStreamingConfig({
          id
        });

        dispatch.dataStreaming.setModalLoading(false, {
          snackbar: {
            text: i18n.t('label.dataStreaming_set_del_msg_success'),
            action: {
              label: i18n.t('action.close')
            }
          }
        });

        dispatch.dataStreaming.getDataStreamings({
          realm: realm,
          mock: false
        });

        dispatch.dataStreaming.setSuccess(true);
      } catch (e) {
        dispatch.dataStreaming.setModalLoading(false, {
          snackbar: {
            text: i18n.t('label.dataStreaming_not_set_del_msg'),
            action: {
              label: i18n.t('action.close')
            }
          }
        });
      } finally {
        setTimeout(() => {
          dispatch.dataStreaming.setSuccess(false);
        }, 1000);
      }
    }
  })
};

export default dataStreamingModel;
