import { toast } from 'react-toastify';
import Immutable from 'seamless-immutable';

import { authentication as api } from '~/api';
import { RECAPTCHA_SITE_KEY, version } from '~/common/helpers/environment';
import i18n from '~/common/helpers/i18n';
import storage from '~/common/helpers/storage';
import WebAnalytics from '~/common/helpers/webAnalytics';

const userData = storage.get('userData');
const initialState = Immutable({
  isSignedIn: !!userData,
  userData,
  loading: false,
  error: null,
  firstaccess: null,
  realmParams: null,
  errorFirstAccess: {
    authentication: null,
    newPassword: false,
    password: false,
    messages: null
  },
  verificationCodeError: false
});

const mapStatusCode = {
  203: 'firstaccess',
  200: 'success'
};

const mapStatusCodeError = {
  failed: data => {
    const errorData = data.details || data.data || [];
    const filterErrorsWithMessage = errorData?.fieldErrors?.password?.filter(
      x => !!x.message
    );
    return {
      password: false,
      newPassword: true,
      authentication: null,
      messages: filterErrorsWithMessage
    };
  },
  Unauthorized: ({ status }) => ({
    password: false,
    newPassword: false,
    failed: true,
    authentication: status
  }),
  400: ({ details, data }) => {
    const errorData = details || data || [];
    const filterErrorsWithMessage = errorData?.fieldErrors?.password?.filter(
      x => !!x.message
    );
    return {
      password: false,
      newPassword: true,
      authentication: null,
      messages: filterErrorsWithMessage
    };
  },
  401: ({ status }) => ({
    password: false,
    newPassword: false,
    authentication: status
  }),
  404: () => ({
    password: true,
    newPassword: false,
    authentication: null
  })
};

const authentication = {
  name: 'authentication',
  state: initialState,
  reducers: {
    success: (state, payload) =>
      state.merge({ isSignedIn: true, userData: payload }),
    failed: (state, error) =>
      state.merge({
        isSignedIn: false,
        userData: null,
        error
      }),
    loginBlocked: (state, error) => state.merge({ error }),
    loginViaIdp: (state, error) => state.merge({ error }),
    loading: (state, value) => state.merge({ loading: value }),
    firstaccess: (state, firstaccess) => state.merge({ firstaccess }),
    verificationCodeSuccess: state =>
      state.merge({
        error: null
      }),
    userTemporaryLocked: (state, error) =>
      state.merge({
        error
      }),
    setNotLoggedPlatformParameter: (state, realmParams) => state.merge({
      realmParams: {
        ...state.realmParams,
        ...realmParams
      }
    }),
    errorFirstAccess: (state, errorFirstAccess) =>
      state.merge({ errorFirstAccess }),
    successFirstAccess: (state, successFirstAccess) =>
      state.merge({ successFirstAccess }),
    reset: () => {
      storage.remove('userData');
      storage.remove('togaiToken');

      return initialState.merge({
        isSignedIn: false,
        userData: undefined
      });
    },
    resetError: state => state.merge({ error: null })
  },

  effects: dispatch => ({
    async signinWithToken({ token, email, realm, lastLogin, createdAt, updatedAt }) {
      dispatch.authentication.success({
        token,
        email,
        realm,
        lastLogin,
        createdAt,
        updatedAt
      });
      WebAnalytics.sendEvent('[Gov] (Login) IDP Sign In Success', {
        realm,
        email
      });
    },
    async signin({ realm, email, password, code }) {
      try {
        const recaptcha = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, {
          action: 'auth'
        });
        const request = await api.signIn(
          realm,
          email,
          password,
          recaptcha,
          version,
          code
        );
        const status = mapStatusCode[request.status];
        dispatch.authentication[status]({
          token: request.headers.authorization || request.data.changePasswordId,
          realm,
          email
        });
        WebAnalytics.sendEvent('[Gov] (Login) Sign In Success', {
          realm,
          email
        });
      } catch (error) {
        const { response } = error;

        if (response?.data?.data?.lockAuth) {
          if (response?.data?.data?.temporaryLocked) {
            dispatch.authentication.userTemporaryLocked({
              lockAuth: true,
              email
            });
          } else {
            dispatch.authentication.loginBlocked({
              lockAuth: true,
              attemptsRemaining: response?.data?.data?.attemptsRemaining,
              realm,
              email
            });
          }
        } else if (response?.data?.data === 'Login via Digibee not allowed') {
          dispatch.authentication.loginViaIdp({
            realm
          });
        } else {
          dispatch.authentication.failed(
            response ? response.data : error.message
          );
        }
      }
    },
    async getNotLoggedPlatformParameter({ realm, param }) {
      try {
        const { data } = await api.getNotLoggedPlatformParameter(realm, param);
        dispatch.authentication.setNotLoggedPlatformParameter(data);
        return data;
      } catch (error) {
        dispatch.authentication.setNotLoggedPlatformParameter(null);
        return error;
      }
    },
    async signOut(_, state) {
      const { token, realm } = state.authentication.userData;
      try {
        await api.signOut(realm, token);
      } catch (e) {
        toast.error(e.message);
      } finally {
        dispatch.profile.reset();
        dispatch.pipelines.clear();
        dispatch.intercom.reset();
        dispatch.authentication.reset();
        dispatch.router.navigate({ to: '/login' });
      }
    },
    async signinFirstAccess(
      { password, newPassword, newPasswordConfirm },
      state
    ) {
      try {
        const { realm, email, token } = state.authentication.firstaccess;
        await api.signInFirstAccess(
          realm,
          email,
          password,
          newPassword,
          newPasswordConfirm,
          token
        );
        dispatch.authentication.successFirstAccess(true);
      } catch (error) {
        if (error?.response?.data?.code === 404) {
          dispatch.authentication.errorFirstAccess({
            authentication: true
          });
        } else {
          dispatch.authentication.errorFirstAccess({
            messages: error?.response?.data?.data?.fieldErrors?.password,
            newPassword: true
          });
        }
      }
    },
    async validateVerificationCode({ verificationCode }, state) {
      try {
        const { realm, email } = state.authentication.error;
        await api.validateVerificationCode(realm, email, verificationCode);
        dispatch.authentication.verificationCodeSuccess();
      } catch (error) {
        const status = error.response?.status;
        if (status === 401) {
          const data = error.response?.data;
          if (data.data?.temporaryLocked) {
            dispatch.authentication.userTemporaryLocked({
              ...state.authentication.error
            });
          } else {
            dispatch.authentication.failed({
              ...state.authentication.error,
              verificationCodeError: true,
              attemptsRemaining: data.data?.attemptsRemaining
            });
          }
        } else {
          dispatch.authentication.failed({
            ...state.authentication.error,
            verificationCodeError: true
          });
        }
      }
    },
    async sendVerificationCode(_, state) {
      try {
        const { realm, email } = state.authentication.error;
        await api.sendVerificationCode(realm, email);
        dispatch.snackbar.create({
          text: i18n.t('label.new_code_sent_msg_success'),
          action: {
            label: i18n.t('action.ok')
          }
        });
      } catch (error) {
        const status = error.response?.status;
        if (status === 409) {
          dispatch.authentication.verificationCodeSuccess();
        } else {
          dispatch.snackbar.create({
            text: i18n.t('common.error.generic_prompt')
          });
        }
      }
    }
  }),
  logics: [
    {
      type: ['authentication/validateVerificationCode'],
      latest: true,
      process(_, dispatch, done) {
        dispatch.authentication.loading(true);
        done();
      }
    },
    {
      type: ['authentication/signin', 'authentication/signinFirstAccess'],
      latest: true,
      process(_, dispatch, done) {
        dispatch.authentication.loading(true);
        dispatch.authentication.resetError();
        done();
      }
    },
    {
      type: [
        'authentication/success',
        'authentication/failed',
        'authentication/errorFirstAccess',
        'authentication/firstaccess',
        'authentication/loginBlocked',
        'authentication/verificationCodeSuccess',
        'authentication/loginViaIdp'
      ],
      latest: true,
      process(_, dispatch, done) {
        dispatch.authentication.loading(false);
        done();
      }
    },
    {
      type: ['authentication/success'],
      latest: true,
      process({ action }, dispatch, done) {
        dispatch.authentication.errorFirstAccess({
          authentication: null,
          failed: false
        });
        dispatch.profile.getUser();
        dispatch.application.getTogaiToken(action?.payload?.realm);
        storage.set('userData', action.payload);
        // TODO: Revisitar.
        dispatch.router.navigate({
          to: `/${action.payload.realm}/operation/build/pipelines`,
          replace: true
        });
        done();
      }
    },
    {
      type: ['authentication/firstaccess'],
      latest: true,
      process(_, dispatch, done) {
        dispatch.router.navigate({ to: '/login/changepassword' });
        done();
      }
    },
    {
      type: ['authentication/loginBlocked'],
      latest: true,
      process(_, dispatch, done) {
        dispatch.router.navigate({ to: '/login/error' });
        done();
      }
    },
    {
      type: ['authentication/verificationCodeSuccess'],
      latest: true,
      process(_, dispatch, done) {
        dispatch.router.navigate({ to: '/login/success' });
        done();
      }
    },
    {
      type: ['authentication/loginViaIdp'],
      latest: true,
      process(_, dispatch, done) {
        dispatch.router.navigate({ to: '/login/identity-provider' });
        done();
      }
    },
    {
      type: ['authentication/userTemporaryLocked'],
      latest: true,
      process(_, dispatch, done) {
        dispatch.router.navigate({ to: '/login/temporary-locked' });
        done();
      }
    }
  ]
};

export default authentication;
