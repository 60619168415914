import { BrowserRouter } from 'react-router-dom';
import {HeroUIProvider} from "@heroui/react";

import Application from './components/Application';
import ReduxProvider from './components/ReduxProvider';

const App = () => (
  <HeroUIProvider>
    <BrowserRouter>
      <ReduxProvider>
        <Application />
      </ReduxProvider>
    </BrowserRouter>
  </HeroUIProvider>
);

export default App;
