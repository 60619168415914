import { isApolloError, gql } from '@apollo/client';
import {
  DataStreaming,
  DataStreamingSavePost,
  DataStreamingSavePut
} from '~/entities/DataStreaming';
import clientApollo from './clientApollo';

type DataStreamingConfigSaveData = {
  realm: string;
  dataStreaming: DataStreaming;
  id?: string;
};

type SaveDataStreamingConfigResult = {
  createDataStreamingConfig: boolean;
};

type UpdatedDataStreamingConfigResult = {
  updateDataStreamingConfig: boolean;
};

type DataStreamingConfigFetchData = {
  realm: string;
};

type DataStreamingConfigFetchAllResult = {
  getDataStreamingConfig: DataStreaming;
};

type RemoveDataStreamingConfigData = {
  realm: string;
  id: string;
};

type RemoveDataStreamingConfigResult = {
  deleteDataStreamingConfig: boolean;
};
const dataStreamingConfig = {
  getDataStreamingConfig: async ({ realm }: DataStreamingConfigFetchData) => {
    try {
      const result =
        await clientApollo.query<DataStreamingConfigFetchAllResult>({
          query: gql`
            query getDataStreamingConfig($realm: String!) {
              getDataStreamingConfig(realm: $realm)
            }
          `,
          variables: {
            realm
          }
        });

      return result.data?.getDataStreamingConfig;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  createDataStreamingConfig: async ({
    realm,
    dataStreaming
  }: DataStreamingConfigSaveData) => {
    try {
      const dataStreamingWithRealm: DataStreamingSavePost = {
        realm: realm,
        exporter: dataStreaming.exporter.toLocaleLowerCase(),
        config: {
          host: dataStreaming.config.host,
          apiKey: dataStreaming?.config?.apiKey || ''
        },
        contexts: dataStreaming.contexts
      };

      const result = await clientApollo.mutate<SaveDataStreamingConfigResult>({
        mutation: gql`
          mutation createDataStreamingConfig($dataStreaming: JSON) {
            createDataStreamingConfig(dataStreaming: $dataStreaming)
          }
        `,
        variables: {
          dataStreaming: dataStreamingWithRealm
        }
      });
      return result.data?.createDataStreamingConfig;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  updateDataStreamingConfig: async ({
    realm,
    dataStreaming
  }: DataStreamingConfigSaveData) => {
    try {
      const dataStreamingWithRealm: DataStreamingSavePut = {
        realm: realm,
        id: dataStreaming.id,
        exporter: dataStreaming.exporter.toLocaleLowerCase(),
        config: {
          host: dataStreaming.config.host,
          apiKey: dataStreaming?.config?.apiKey
        },
        contexts: dataStreaming.contexts
      };

      const result =
        await clientApollo.mutate<UpdatedDataStreamingConfigResult>({
          mutation: gql`
            mutation updateDataStreamingConfig($dataStreaming: JSON) {
              updateDataStreamingConfig(dataStreaming: $dataStreaming)
            }
          `,
          variables: {
            dataStreaming: dataStreamingWithRealm
          }
        });
      return result.data?.updateDataStreamingConfig;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  removeDataStreamingConfig: async ({ id }: RemoveDataStreamingConfigData) => {
    try {
      const result = await clientApollo.mutate<RemoveDataStreamingConfigResult>(
        {
          mutation: gql`
            mutation deleteDataStreamingConfig($id: String!) {
              deleteDataStreamingConfig(id: $id)
            }
          `,
          variables: {
            id: id
          }
        }
      );
      return result.data?.deleteDataStreamingConfig;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },

  // MOCK
  getDataStreamingConfigMOCK: async ({
    realm
  }: DataStreamingConfigFetchData) => {
    try {
      console.log('data streaming api');

      console.log('data streaming api mock');
      return {
        content: [
          {
            id: '0085e0c9-0a6d-44d6-8f6a-3e160f279a6a',
            realm: 'staging',
            exporter: 'DATADOG',
            config: {
              host: 'us5.datadoghq.com',
              apiKey: ''
            },
            contexts: ['LOGS', 'ERROR'],
            status: {
              status: 'DOWN',
              lastCheckTime: '2025-03-26T00:11:40.526276313Z',
              contextStatus: {
                LOGS: 'DOWN'
              }
            }
          }
        ]
      };
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  }
};

export default dataStreamingConfig;
