import { iff } from '@digibee/control-statements';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import * as Elements from './Sidebar.elements';

import { TOGAI_URL, TOGAI_ORGANIZATION_ID } from '~/common/helpers/environment';
import i18n from '~/common/helpers/i18n';
import useContainPaths from '~/common/hooks/useContainPaths';
import paths from '~/common/jsons/paths.json';
import theme from '~/common/styled/theme';
import Role from '~/components/Role';

const Sidebar = ({
  realm,
  realmData,
  license,
  showTogaiRedirectButton,
  togaiToken
}) => {
  const containPaths = useContainPaths();
  const [pathName, setPathName] = useState(`${realm}${paths.settings}`);
  const { application } = useSelector(state => state);

  return (
    <Elements.Container name={pathName}>
      <Elements.WrapperLogo>
        <Elements.Logo src={theme.images.pictureNewLogoDigibeeWithText} />
      </Elements.WrapperLogo>
      <Elements.Nav>
        <Role name='ORG:MANAGER'>
          <Elements.WrapperTitle data-testid='organization'>
            <Elements.IconTitle name='Sitemap' data-testid='icon' />
            <Elements.Title
              data-testid='text'
              active={containPaths([`${realm}${paths.organization_groups}`])}
              align='left'
              size='lg'
              weight='medium'
            >
              {i18n.t('scenes.beta.labels.organization')}
            </Elements.Title>
          </Elements.WrapperTitle>
          <Elements.WrapperLink>
            <Elements.ContentLink
              data-testid='governance-organization-groups'
              active={containPaths([`${realm}${paths.organization_groups}`])}
            >
              <Elements.Link
                data-testid='link'
                to={`/${realm}${paths.organization_groups}`}
              >
                <Elements.TextLink
                  data-testid='text'
                  align='left'
                  weight='regular'
                  size='md'
                  active={containPaths([
                    `/${realm}${paths.organization_groups}`
                  ])}
                >
                  {i18n.t('scenes.beta.labels.organization_groups')}
                </Elements.TextLink>
              </Elements.Link>
            </Elements.ContentLink>
          </Elements.WrapperLink>
        </Role>

        <Elements.WrapperTitle data-testid='administration'>
          <Elements.IconTitle name='UserLock' data-testid='icon' />
          <Elements.Title
            data-testid='text'
            active={containPaths([
              `${realm}${paths.user}`,
              `${realm}${paths.audit}`,
              `${realm}${paths.ctl}`,
              `${realm}${paths.groups}`,
              `${realm}${paths.token}`,
              `${realm}${paths.beta_user}`,
              `${realm}${paths.roles}`,
              `${realm}${paths.identity_provider}`
            ])}
            align='left'
            size='lg'
            weight='medium'
          >
            {i18n.t('scenes.beta.labels.administration')}
          </Elements.Title>
        </Elements.WrapperTitle>
        <Elements.WrapperLink>
          <Role name='AUDIT:READ'>
            <Elements.ContentLink
              data-testid='audit-administration-audit'
              active={containPaths([`${realm}${paths.audit}`])}
            >
              <Elements.Link data-testid='link' to={`/${realm}${paths.audit}`}>
                <Elements.TextLink
                  data-testid='text'
                  align='left'
                  weight='regular'
                  size='md'
                  active={containPaths([`/${realm}${paths.audit}`])}
                >
                  {i18n.t('scenes.beta.labels.audit')}
                </Elements.TextLink>
              </Elements.Link>
            </Elements.ContentLink>
          </Role>
          <Role name={['USER:READ']}>
            <Elements.ContentLink
              data-testid='user-administration-user'
              active={containPaths([`${realm}${paths.beta_user}`])}
            >
              <Elements.Link
                data-testid='link'
                to={`/${realm}${paths.beta_user}`}
                onClick={() => setPathName(`${realm}${paths.beta_user}`)}
                flex
                active={containPaths([`${realm}${paths.beta_user}`])}
              >
                <Elements.TextLink
                  data-testid='text'
                  active={containPaths([`${realm}${paths.beta_user}`])}
                  align='left'
                  weight='regular'
                  size='md'
                >
                  {`${i18n.t('noun.user_plural')}`}
                </Elements.TextLink>
                {iff(realmData?.oldPermissionRevoked === false, () => (
                  <Elements.Badger>Beta</Elements.Badger>
                ))}
              </Elements.Link>
            </Elements.ContentLink>
          </Role>
          <Role name={['GROUP:READ']}>
            <Elements.ContentLink
              data-testid='groups-administration-groups'
              active={containPaths([`${realm}${paths.groups}`])}
            >
              <Elements.Link
                data-testid='link'
                to={`/${realm}${paths.groups}/list`}
                onClick={() => setPathName(`${realm}${paths.groups}`)}
                flex
                active={containPaths([`${realm}${paths.groups}`])}
              >
                <Elements.TextLink
                  data-testid='text'
                  active={containPaths([`${realm}${paths.groups}`])}
                  align='left'
                  weight='regular'
                  size='md'
                >
                  {i18n.t('scenes.groups.labels.groups')}
                </Elements.TextLink>
                {iff(realmData?.oldPermissionRevoked === false, () => (
                  <Elements.Badger>Beta</Elements.Badger>
                ))}
              </Elements.Link>
            </Elements.ContentLink>
          </Role>
          <Role name={['ROLE:READ']}>
            <Elements.ContentLink
              data-testid='roles-administration-roles'
              active={containPaths([`${realm}${paths.roles}`])}
            >
              <Elements.Link
                data-testid='link'
                to={`/${realm}${paths.roles}`}
                onClick={() => setPathName(`${realm}${paths.roles}`)}
                flex
                active={containPaths([`${realm}${paths.roles}`])}
              >
                <Elements.TextLink
                  data-testid='text'
                  active={containPaths([`${realm}${paths.roles}`])}
                  align='left'
                  weight='regular'
                  size='md'
                >
                  {i18n.t('scenes.roles.labels.roles')}
                </Elements.TextLink>
                {iff(realmData?.oldPermissionRevoked === false, () => (
                  <Elements.Badger>Beta</Elements.Badger>
                ))}
              </Elements.Link>
            </Elements.ContentLink>
          </Role>
          <Role name={['SSO-CONFIGURATION:READ']}>
            <Elements.ContentLink
              data-testid='accesses_via_idp-administration-accesses_via_idp'
              active={containPaths([`${realm}${paths.identity_provider}`])}
            >
              <Elements.Link
                data-testid='link'
                to={`/${realm}${paths.identity_provider}/idp-settings`}
                onClick={() =>
                  setPathName(`${realm}${paths.identity_provider}`)
                }
                flex
                active={containPaths([`${realm}${paths.identity_provider}`])}
              >
                <Elements.TextLink
                  data-testid='text'
                  active={containPaths([`${realm}${paths.identity_provider}`])}
                  align='left'
                  weight='regular'
                  size='md'
                >
                  {i18n.t('action.identitiy_provider')}
                </Elements.TextLink>
              </Elements.Link>
            </Elements.ContentLink>
          </Role>
          {iff(realmData?.oldPermissionRevoked === false, () => (
            <Role name={['USER:READ']}>
              <Elements.ContentLink
                active={containPaths([`${realm}${paths.user}`])}
              >
                <Elements.Link
                  to={`/${realm}${paths.user}`}
                  onClick={() => setPathName(`${realm}${paths.user}`)}
                  active={containPaths([`${realm}${paths.user}`])}
                >
                  <Elements.TextLink
                    active={containPaths([`${realm}${paths.user}`])}
                    align='left'
                    weight='regular'
                    size='md'
                  >
                    {i18n.t('noun.user_plural')}
                  </Elements.TextLink>
                </Elements.Link>
              </Elements.ContentLink>
            </Role>
          ))}
          <Role name={['USER:READ:LIST-JWT']}>
            <Elements.ContentLink
              active={containPaths([`${realm}${paths.token}`])}
            >
              <Elements.Link
                to={`/${realm}${paths.token}`}
                onClick={() => setPathName(`${realm}${paths.token}`)}
                active={containPaths([`${realm}${paths.token}`])}
              >
                <Elements.TextLink
                  active={containPaths([`${realm}${paths.token}`])}
                  weight='regular'
                  align='left'
                  size='md'
                >
                  {i18n.t('scenes.tokenctl.labels.digibeectl')}
                </Elements.TextLink>
              </Elements.Link>
            </Elements.ContentLink>
          </Role>
        </Elements.WrapperLink>
        <Role
          environmentCapable
          name={[
            'CONSUMER:READ',
            'GLOBAL:READ',
            'ACCOUNT:READ',
            'RELATION:READ',
            'REPLICA:READ',
            'APIKEY:READ'
          ]}
        >
          <Elements.WrapperTitle>
            <Elements.IconTitle name='SlidersVSquare' />
            <Elements.Title
              active={containPaths([
                `${realm}${paths.globals}`,
                `${realm}${paths.accounts}`,
                `${realm}${paths.consumers}`,
                `${realm}${paths.relationship}`,
                `${realm}${paths.multi_instance}`,
                `${realm}${paths.capsule_headers}`,
                `${realm}${paths.data_streaming}`
              ])}
              align='left'
              size='lg'
              weight='medium'
            >
              {i18n.t('scenes.beta.labels.setting_plural')}
            </Elements.Title>
          </Elements.WrapperTitle>
        </Role>
        <Elements.WrapperLink>
          <Role name='GLOBAL:READ'>
            <Elements.ContentLink
              active={containPaths([`${realm}${paths.globals}`])}
            >
              <Elements.Link to={`/${realm}${paths.globals}`}>
                <Elements.TextLink
                  align='left'
                  weight='regular'
                  size='md'
                  active={containPaths([`${realm}${paths.globals}`])}
                >
                  {i18n.t('scenes.beta.labels.global_plural')}
                </Elements.TextLink>
              </Elements.Link>
            </Elements.ContentLink>
          </Role>
          <Role name='ACCOUNT:READ'>
            <Elements.ContentLink
              active={containPaths([
                `${realm}${paths.accounts}`,
                `${realm}${paths.oauth_providers}`
              ])}
            >
              <Elements.Link to={`/${realm}${paths.accounts}`}>
                <Elements.TextLink
                  active={containPaths([
                    `${realm}${paths.accounts}`,
                    `${realm}${paths.oauth_providers}`
                  ])}
                  weight='regular'
                  align='left'
                  size='md'
                >
                  {i18n.t('label.account_plural')}
                </Elements.TextLink>
              </Elements.Link>
            </Elements.ContentLink>
          </Role>
          <Role name='APIKEY:READ' environmentCapable>
            <Elements.ContentLink
              active={containPaths([`${realm}${paths.api_keys}`])}
            >
              <Elements.Link flex to={`/${realm}${paths.api_keys}`}>
                <Elements.TextLink
                  active={containPaths([`${realm}${paths.api_keys}`])}
                  weight='regular'
                  align='left'
                  size='md'
                >
                  {i18n.t('action.consumers_api_keys')}
                </Elements.TextLink>
              </Elements.Link>
            </Elements.ContentLink>
          </Role>
          <Role name='RELATION:READ'>
            <Elements.ContentLink
              active={containPaths([`${realm}${paths.relationship}`])}
            >
              <Elements.Link to={`/${realm}${paths.relationship}`}>
                <Elements.TextLink
                  active={containPaths([`${realm}${paths.relationship}`])}
                  weight='regular'
                  align='left'
                  size='md'
                >
                  {i18n.t('scenes.beta.labels.relationship')}
                </Elements.TextLink>
              </Elements.Link>
            </Elements.ContentLink>
          </Role>
          <Role name='REPLICA:READ'>
            <Elements.ContentLink
              active={containPaths([`${realm}${paths.multi_instance}`])}
            >
              <Elements.Link to={`/${realm}${paths.multi_instance}`}>
                <Elements.TextLink
                  active={containPaths([`${realm}${paths.multi_instance}`])}
                  weight='regular'
                  align='left'
                  size='md'
                >
                  {i18n.t('scenes.beta.labels.multi_instance')}
                </Elements.TextLink>
              </Elements.Link>
            </Elements.ContentLink>
          </Role>
          <Role name='CAPSULE:READ'>
            <Elements.ContentLink
              active={containPaths([`${realm}${paths.capsule_headers}`])}
            >
              <Elements.Link to={`/${realm}${paths.capsule_headers}`}>
                <Elements.TextLink
                  active={containPaths([`${realm}${paths.capsule_headers}`])}
                  weight='regular'
                  align='left'
                  size='md'
                >
                  {i18n.t('noun.capsules_title')}
                </Elements.TextLink>
              </Elements.Link>
            </Elements.ContentLink>
          </Role>
          <Role name='CAPSULE:READ'>
            <Elements.ContentLink
              active={containPaths([`${realm}${paths.data_streaming}`])}
            >
              <Elements.Link to={`/${realm}${paths.data_streaming}`}>
                <Elements.TextLink
                  active={containPaths([`${realm}${paths.data_streaming}`])}
                  weight='regular'
                  align='left'
                  size='md'
                >
                  {i18n.t('noun.data_streaming_title')}
                </Elements.TextLink>
              </Elements.Link>
            </Elements.ContentLink>
          </Role>
        </Elements.WrapperLink>

        <Elements.WrapperTitle>
          <Elements.IconTitle name='Landmark' />
          <Elements.Title
            active={containPaths([`${realm}${paths.policies}`])}
            align='left'
            size='lg'
            weight='medium'
          >
            {i18n.t('label.governance')}
          </Elements.Title>
        </Elements.WrapperTitle>
        <Elements.WrapperLink>
          <Role name='POLICY:READ'>
            <Elements.ContentLink
              active={containPaths([`${realm}${paths.policies}`])}
              data-testid='governance-settings-policies-page-menu'
            >
              <Elements.Link
                data-testid='link'
                flex
                to={`/${realm}${paths.policies}/security`}
              >
                <Elements.TextLink
                  align='left'
                  weight='regular'
                  size='md'
                  active={containPaths([`${realm}${paths.policies}`])}
                  data-testid='text'
                >
                  {i18n.t('action.policies')}
                </Elements.TextLink>
              </Elements.Link>
            </Elements.ContentLink>
          </Role>
        </Elements.WrapperLink>

        {iff(license?.planName !== 'Production Subscription', () => (
          <>
            <Elements.WrapperTitle>
              <Elements.IconTitle name='ChartUser' />
              <Elements.Title
                active={containPaths([`${realm}${paths.licensing}`])}
                align='left'
                size='lg'
                weight='medium'
              >
                {i18n.t('label.subscription')}
              </Elements.Title>
            </Elements.WrapperTitle>
            <Elements.WrapperLink>
              {iff(!showTogaiRedirectButton, () => (
                <Role name='LICENSE:READ'>
                  <Elements.ContentLink
                    active={containPaths([`${realm}${paths.licensing}`])}
                    data-testid='licensing-settings-licensing-page-menu'
                  >
                    <Elements.Link
                      data-testid='link'
                      flex
                      to={`/${realm}${paths.licensing}`}
                    >
                      <Elements.TextLink
                        align='left'
                        weight='regular'
                        size='md'
                        active={containPaths([`${realm}${paths.licensing}`])}
                        data-testid='text'
                      >
                        {i18n.t('action.my_usage')}
                      </Elements.TextLink>
                    </Elements.Link>
                  </Elements.ContentLink>
                </Role>
              ))}
              {iff(
                license?.planName === 'Consumption Based Model' &&
                  !!togaiToken &&
                  showTogaiRedirectButton,
                () => (
                  <Role name='LICENSE:READ'>
                    <Elements.ContentLink
                      active={containPaths([`${realm}${paths.licensing}`])}
                      data-testid='licensing-settings-licensing-page-menu'
                    >
                      <Elements.Link
                        data-testid='link'
                        flex
                        to='#'
                        onClick={() =>
                          window
                            .open(
                              `${TOGAI_URL}/${TOGAI_ORGANIZATION_ID}?access-token=${togaiToken}`,
                              '_blank'
                            )
                            .focus()
                        }
                      >
                        <Elements.TextLink
                          align='left'
                          weight='regular'
                          externalLink
                          size='md'
                          active={containPaths([`${realm}${paths.licensing}`])}
                          data-testid='text'
                        >
                          {i18n.t('action.my_usage')}
                        </Elements.TextLink>
                        <Elements.IconExternal
                          faType='regular'
                          name='ArrowUpRightFromSquare'
                        />
                      </Elements.Link>
                    </Elements.ContentLink>
                  </Role>
                )
              )}
              {iff(
                license?.planName === 'Subscription Based Model' ||
                  license?.planName === 'Pipeline Based Model',
                () => (
                  <Role name='LICENSE:READ'>
                    <Elements.ContentLink
                      active={containPaths([`${realm}/settings/limits`])}
                      data-testid='licensing-settings-limits-page-menu'
                    >
                      <Elements.Link
                        data-testid='link'
                        flex
                        to={`/${realm}/settings/limits/global`}
                      >
                        <Elements.TextLink
                          align='left'
                          weight='regular'
                          size='md'
                          active={containPaths([`${realm}/settings/limits`])}
                          data-testid='text'
                        >
                          {i18n.t('action.usage_limits')}
                        </Elements.TextLink>
                      </Elements.Link>
                    </Elements.ContentLink>
                  </Role>
                )
              )}
            </Elements.WrapperLink>
          </>
        ))}

        <Elements.WrapperTitle>
          <Elements.IconTitle name='Bell' />
          <Elements.Title
            align='left'
            size='lg'
            weight='medium'
            active={containPaths([`${realm}${paths.alert}`])}
          >
            {i18n.t('label.notifications')}
          </Elements.Title>
        </Elements.WrapperTitle>
        <Elements.WrapperLink>
          <Role name='ALERT:READ'>
            <Elements.ContentLink
              active={containPaths([`${realm}${paths.alert}`])}
            >
              <Elements.Link
                flex
                to={`/${realm}${paths.monitor_alert}/${application.environments.allNames[0]}`}
                data-testid='alerts-settings-alerts-page-sidebar-menu'
              >
                <Elements.TextLink
                  align='left'
                  weight='regular'
                  size='md'
                  active={containPaths([
                    `${realm}${paths.alert}/${application.environments.allNames[0]}`
                  ])}
                >
                  {i18n.t('action.alerts')}
                </Elements.TextLink>
              </Elements.Link>
            </Elements.ContentLink>
          </Role>
        </Elements.WrapperLink>

        <Role name='USER:READ'>
          <Elements.WrapperTitle>
            <Elements.IconTitle name='CodePullRequest' />
            <Elements.Title
              align='left'
              size='lg'
              weight='medium'
              active={containPaths([`${realm}${paths.credentials}`])}
            >
              {i18n.t('label.digibee_apis')}
            </Elements.Title>
          </Elements.WrapperTitle>
          <Elements.ContentLink
            active={containPaths([`${realm}${paths.credentials}`])}
          >
            <Elements.Link
              flex
              to={`/${realm}${paths.credentials}`}
              data-testid='monitor-api-management-settings-credentials-page-sidebar-menu'
            >
              <Elements.TextLink
                align='left'
                weight='regular'
                size='md'
                active={containPaths([`${realm}${paths.credentials}}`])}
              >
                {i18n.t('action.credentials')}
              </Elements.TextLink>
              <Elements.Badger>Beta</Elements.Badger>
            </Elements.Link>
          </Elements.ContentLink>
        </Role>
      </Elements.Nav>
    </Elements.Container>
  );
};

Sidebar.defaultProps = {
  realm: '',
  togaiToken: null
};

Sidebar.propTypes = {
  realm: PropTypes.string,
  realmData: PropTypes.objectOf({}).isRequired,
  license: PropTypes.objectOf({}).isRequired,
  showTogaiRedirectButton: PropTypes.bool.isRequired,
  togaiToken: PropTypes.string
};

export default Sidebar;
