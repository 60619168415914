
import { isApolloError, gql } from '@apollo/client';
import axios, { isAxiosError } from 'axios';
import queryString from 'query-string';

import clientApollo from './clientApollo';
import { URL } from './variables';

import { API_KEY } from '~/common/helpers/environment';
import { PlanName } from '~/scenes/Governance/types/Plan';

export type LicenseEnvironment = {
  [keyof: string]: {
    usedPipeline: number;
    licensesDeployed: number;
  };
};

export enum HealthStatus {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED'
}

export type Limit = {
  name: string;
  myLimit: number | string;
  unitOfMeasure: string;
  limitUsed: number | string;
  nextUpdate: string;
  healthStatus: HealthStatus;
  pipeline: string | null;
  project: string | null;
};

export type Project = {
  name: string;
  description: string;
};

export type ProjectUsage = {
  name: string;
  description?: string;
  pipelineUsageProd: number;
  pipelineUsageTest: number;
  rtuUsageProd: number;
  rtuUsageTest: number;
  environment: string;
  typeProject: 'LARGE' | 'SMALL' | 'MEDIUM' | 'UNDEFINED';
};

export type PipelinesUsage = {
  environment: string;
  pipelineName: string;
  replica: number;
  rtu: number;
  projectName: string;
  size: string;
  concurrentExection?: number;
  projectDescription?: string;
  pipelineVersion?: string;
};

export type PipelineAndCapacityBasedModel = {
  licenses: number;
  licensesAvailable: number;
  environment: LicenseEnvironment;
};

export type License = {
  planName: PlanName;
  pipelineBasedModel?: {
    licenses: number;
    licensesAvailable: number;
    environment: {
      [keyof: string]: {
        usedPipeline: number;
        licensesDeployed: number;
      };
    };
  };
  capacityBasedModel?: {
    licenses: number;
    licensesAvailable: number;
    environment: {
      [keyof: string]: {
        usedPipeline: number;
        licensesDeployed: number;
      };
    };
  };
  subscriptionBasedModel?: {
    environment: {
      [keyof: string]: {
        rtu: {
          allowedRtu: number;
          availableRtu: number;
          usedRtu: number;
        };
        subscription: {
          allowedPipeline: number;
          availablePipeline: number;
          usedPipeline: number;
        };
      };
    };
  };
  projectsInformation?: {
    projects: ProjectUsage[];
  };
};

export type RealmContacts = {
  CSM: {
    name: string;
    email: string;
  };
  HEAD: {
    name: string;
    email: string;
  };
  FUNCTIONAL_ANALYST: {
    name: string;
    email: string;
  };
};

export type LimitOfUse = {
  usageLimits: Limit[];
};

export type TrafficLight = {
  metric: string;
  pipeline: string | null;
  project: string | null;
  realm: string;
  status: string;
  environment: string;
  createdAt: string;
  limitUsed: number | string;
  limitAcquired: number | string;
};

export type LimitDetails = {
  limitUsed: number | string;
  limitAcquired: number | string;
  infractions: TrafficLight[];
  trafficLights: TrafficLight[];
};

export type ProposalUpsell = {
  rtu: number;
  proposalRtu: number;
  realmLimits: {
    name: string;
    myLimit: number | string;
    unitOfMeasure: string;
  }[];
  pipelineLimits?: {
    name: string;
    myLimit: number | string;
    unitOfMeasure: string;
  }[];
};

type DownloadProjectsParams = {
  dateTo: string;
  dateFrom: string;
  allPeriod: boolean;
};

type DownloadProjectsPerPipelineParams = DownloadProjectsParams & {
  projects: Array<string>;
  isDetailedExport?: boolean;
};

type DownloadDailyConsumptionParams = DownloadProjectsParams & {
  project: string;
  pipelines: Array<string>;
};

const license = {
  getLicense: async ({
    realm,
    projectsInformation = false,
    environment,
    token
  }: {
    realm: string;
    projectsInformation: boolean;
    environment: string;
    token: string;
  }) => {
    try {
      const { data } = await axios.get(
        `${URL}/runtime/realms/${realm}/info/license?projectsInformation=${projectsInformation}&environment=${environment}`,
        {
          headers: {
            authorization: token,
            apiKey: API_KEY,
            'Content-Type': 'application/json'
          }
        }
      );

      return data;
    } catch (error) {
      if (error instanceof Error && isAxiosError(error))
        throw Error(error.message);
      throw error;
    }
  },
  getConsumptionBasedMetrics: (realm: string) =>
    clientApollo.query({
      query: gql`
        query consumptionBasedMetrics($realm: String!) {
          consumptionBasedMetrics(realm: $realm) {
            metrics {
              overage
              standard
              updatedAt
              startAt
              expirationAt
              granted
              available
              spent
              currencyCode
              costPerMetric {
                gbSecond
                numberExecution
                egress
              }
              monthOnMonth {
                month
                year
                value
              }
              costPerEnvironment
            }
            metricsPrices {
              NUMBER_EXECUTION
              NUMBER_EXECUTION_OVERAGE
              EGRESS
              EGRESS_OVERAGE
              GB_SECOND
              GB_SECOND_OVERAGE
            }
            creditsHistory {
              name
              status
              startAt
              expiryAt
              priority
              granted
            }
            consumptionByProjects {
              projectName
              totalPipelines
              environments
              pipelines {
                pipelineName
                environment
                gbSecond {
                  spentMetric
                  costCurrency
                }
                numberOfExecution {
                  spentMetric
                  costCurrency
                }
                egress {
                  spentMetric
                  costCurrency
                }
              }
            }
          }
        }
      `,
      variables: {
        realm
      }
    }),
  downloadCreditsInformation: (realm: string) =>
    clientApollo.query({
      query: gql`
        query downloadCreditsInformation($realm: String!) {
          downloadCreditsInformation(realm: $realm)
        }
      `,
      variables: {
        realm
      }
    }),
  downloadProjectsPerPipeline: async (
    {
      allPeriod,
      dateFrom,
      dateTo,
      projects,
      isDetailedExport
    }: DownloadProjectsPerPipelineParams,
    realm: string,
    token: string
  ) => {

    const headers = {
      headers: {
        authorization: token,
        apikey: API_KEY
      }
    };

    if (!isDetailedExport) {
      const params = queryString.stringify({
        dateTo,
        dateFrom,
        projects,
        allPeriod
      });
      const { data } = await axios.post(
        `${URL}/design/realms/${realm}/license-consumption/export/projects/overview?${params}`,
        {},
        headers
      );
      return data;
    }
    const params = queryString.stringify({
      dateTo,
      dateFrom,
      allPeriod
    });
    const { data } = await axios.post(
      `${URL}/design/realms/${realm}/license-consumption/export/projects/all/pipelines/overview?${params}`,
      {},
      headers
    );
    return data;

  },
  downloadDailyConsumption: async (
    {
      allPeriod,
      dateFrom,
      dateTo,
      pipelines,
      project
    }: DownloadDailyConsumptionParams,
    realm: string,
    token: string
  ) => {
    const params = queryString.stringify({
      dateTo,
      dateFrom,
      allPeriod,
      pipelines
    });
    const { data } = await axios.post(
      `${URL}/design/realms/${realm}/license-consumption/export/projects/${encodeURI(
        project
      )}/pipelines/overview?${params}`,
      {},
      {
        headers: {
          authorization: token,
          apikey: API_KEY
        }
      }
    );

    return data;
  },
  getConsumptionByProjects: (realm: string, names: Array<string>) =>
    clientApollo.query({
      query: gql`
        query consumptionProjects($realm: String!, $names: [String]) {
          consumptionProjects(realm: $realm, names: $names) {
            projectName
            totalPipelines
            environments
            pipelines {
              pipelineName
              environment
              gbSecond {
                spentMetric
                costCurrency
              }
              numberOfExecution {
                spentMetric
                costCurrency
              }
              egress {
                spentMetric
                costCurrency
              }
            }
          }
        }
      `,
      variables: {
        realm,
        names
      }
    }),
  getRealmContacts: (realm: string) =>
    clientApollo.query<{ realmContacts: RealmContacts }>({
      query: gql`
        query realmContacts($realm: String!) {
          realmContacts(realm: $realm)
        }
      `,
      variables: {
        realm
      }
    }),
  getLimitOfUse: ({
    realm,
    environment,
    isGlobal,
    pipeline,
    status
  }: {
    realm: string;
    environment: string;
    isGlobal: boolean;
    pipeline?: string;
    status?: string[];
  }) =>
    clientApollo.query<{ limitOfUse: LimitOfUse }>({
      query: gql`
        query limitOfUse(
          $realm: String!
          $environment: String!
          $isGlobal: Boolean
          $pipeline: String
          $status: [String]
        ) {
          limitOfUse(
            realm: $realm
            environment: $environment
            isGlobal: $isGlobal
            pipeline: $pipeline
            status: $status
          )
        }
      `,
      variables: {
        realm,
        environment,
        isGlobal,
        pipeline,
        status
      }
    }),
  getPipelinesUsage: async ({
    realm,
    environment,
    project
  }: {
    realm: string;
    environment: string;
    project?: string;
  }) => {
    try {
      const response = await clientApollo.query<{
        pipelinesUsage: {
          deployments: PipelinesUsage[];
          project: Project | null;
        };
      }>({
        query: gql`
          query pipelinesUsage(
            $realm: String!
            $environment: String
            $project: String
          ) {
            pipelinesUsage(
              realm: $realm
              environment: $environment
              project: $project
            )
          }
        `,
        variables: {
          realm,
          environment,
          project
        }
      });
      return response?.data;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  getLimitOfUseDetail: async ({
    realm,
    environment,
    type,
    dateFrom,
    dateTo
  }: {
    realm: string;
    environment: string;
    type: string;
    dateFrom: string;
    dateTo: string;
  }) => {
    try {
      const response = await clientApollo.query<{
        limitOfUseDetail: LimitDetails;
      }>({
        query: gql`
          query limitOfUseDetail(
            $realm: String!
            $environment: String!
            $type: String!
            $dateFrom: String!
            $dateTo: String!
          ) {
            limitOfUseDetail(
              realm: $realm
              environment: $environment
              type: $type
              dateFrom: $dateFrom
              dateTo: $dateTo
            )
          }
        `,
        variables: {
          realm,
          environment,
          type,
          dateFrom,
          dateTo
        }
      });
      return response?.data;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  getProposalUpsell: async ({
    realm,
    environment
  }: {
    realm: string;
    environment: string;
  }) => {
    try {
      const response = await clientApollo.query<{
        proposalUpsell: ProposalUpsell;
      }>({
        query: gql`
          query proposalUpsell($realm: String!, $environment: String!) {
            proposalUpsell(realm: $realm, environment: $environment)
          }
        `,
        variables: {
          realm,
          environment
        }
      });
      return response?.data;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  }
};

export default license;
